import React from "react";
import HeaderMenu from "./HeaderMenu";
import styles from "./Header.module.css";
import menu from "./HeaderMenu.module.css";
import { useState } from "react";
import faceLight from "../App/assets/pixels.png"; // TODO: change this logo
// "https://images.pexels.com/photos/34153/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350";

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(
    window.innerWidth > 650 ? true : false
  );
  return (
    <>
      <div className={`${styles.header}`}>
        <div className={styles.iconText}>
          <div
            className={styles.profileIcon}
            style={{ backgroundImage: "url(" + faceLight + ")" }}
          ></div>
          <span className={styles.typewriter}>welcome to this page :)</span>
        </div>{" "}
        <i
          className={menuOpen ? "icofont-close" : "icofont-navigation-menu"}
          style={{ fontSize: "22px" }}
          onClick={() => setMenuOpen(!menuOpen)}
        ></i>
      </div>
      {menuOpen ? <HeaderMenu></HeaderMenu> : null}
    </>
  );
}
