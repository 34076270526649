import React from "react";
import styles from "./Teams.module.css";
import common from "./CommonStyles.module.css";
import { useState } from "react";
export default function Books() {
  const [inputVal, setInputVal] = useState("");
  const startsWith = (letter) => {
    const lowerCase = letter.toLowerCase();
    return (
      inputVal.length === 0 ||
      inputVal[0] === letter ||
      inputVal[0] === lowerCase
    );
  };
  return (
    <div className={styles.teamsContainer}>
      <div className={styles.inputCtr}>
        <i
          className={`icofont-search-1 ${styles.searchIcon} ${common.mr4}`}
        ></i>
        <input
          className={styles.findTeam}
          placeholder="Find a recently loved book..."
          value={inputVal}
          onChange={(e) => setInputVal(e.target.value)}
        ></input>
      </div>

      {startsWith("E") ? (
        <div className={styles.team}>
          <i className={`icofont-book ${common.mr4} ${styles.icon}`}></i>
          <a
            className={styles.teamName}
            href={
              "https://www.goodreads.com/book/show/42872088-the-education-of-an-idealist"
            }
          >
            Education of an Idealist/Samantha Power
          </a>
        </div>
      ) : null}
      {startsWith("H") ? (
        <div className={styles.team}>
          <i
            className={`icofont-runner-alt-1 ${common.mr4} ${styles.icon}`}
          ></i>
          <a
            className={styles.teamName}
            href={"https://www.goodreads.com/book/show/31138556"}
          >
            Homo Deus/Yuval Noah Harari
          </a>
        </div>
      ) : null}
      {startsWith("T") ? (
        <div className={styles.team}>
          <i className={`icofont-shield ${common.mr4} ${styles.icon}`}></i>
          <a
            className={styles.teamName}
            href={"https://www.goodreads.com/book/show/11250317"}
          >
            The Song of Achilles/Madeline Miller
          </a>
        </div>
      ) : null}
      {startsWith("R") ? (
        <div className={styles.team}>
          <i className={`icofont-refresh ${common.mr4} ${styles.icon}`}></i>
          <a
            href={"https://www.goodreads.com/book/show/42046112"}
            className={styles.teamName}
          >
            Recursion/Blake Crouch
          </a>
        </div>
      ) : null}
    </div>
  );
}
