import React from "react";
import styles from "./Teams.module.css";
import common from "./CommonStyles.module.css";
import { useState } from "react";
export default function Projects() {
  const [inputVal, setInputVal] = useState("");
  const startsWith = (letter) => {
    const lowerCase = letter.toLowerCase();
    return (
      inputVal.length === 0 ||
      inputVal[0] === letter ||
      inputVal[0] === lowerCase
    );
  };
  return (
    <div className={styles.teamsContainer}>
      <div className={styles.inputCtr}>
        <i
          className={`icofont-search-1 ${styles.searchIcon} ${common.mr4}`}
        ></i>
        <input
          className={styles.findTeam}
          placeholder="Find a project..."
          value={inputVal}
          onChange={(e) => setInputVal(e.target.value)}
        ></input>
      </div>

      {startsWith("P") ? (
        <div className={styles.team}>
          <i className={`icofont-list ${common.mr4} ${styles.icon}`}></i>
          <a
            className={styles.teamName}
            href="https://mohaiminalaoun.github.io/Planner-App"
          >
            Planner App/Offline Meter-based Taskboard
          </a>
        </div>
      ) : null}
      {startsWith("F") ? (
        <div className={styles.team}>
          <i className={`icofont-sub-listing ${common.mr4} ${styles.icon}`}></i>
          <a
            className={styles.teamName}
            href="https://www.npmjs.com/package/floating-options-menu"
          >
            Floating-options-menu/Open-source NPM Package
          </a>
        </div>
      ) : null}
      {startsWith("N") ? (
        <div className={styles.team}>
          <i className={`icofont-map ${common.mr4} ${styles.icon}`}></i>
          <a
            className={styles.teamName}
            href="https://github.com/mohaiminalaoun/News_and_Map_React"
          >
            News Maps/Location Based News Visualizations
          </a>
        </div>
      ) : null}
      {startsWith("R") ? (
        <div className={styles.team}>
          <i className={`icofont-dollar-true ${common.mr4} ${styles.icon}`}></i>
          <a
            className={styles.teamName}
            href={"https://github.com/mohaiminalaoun/Robinhood-Clone"}
          >
            Robinhood Clone/App clone with custom charts
          </a>
        </div>
      ) : null}
    </div>
  );
}
