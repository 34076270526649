import React from "react";
import menu from "./HeaderMenu.module.css";
import common from "./CommonStyles.module.css";
import resumepdf from "../SearchResults/assets/resumepdf.pdf";
import { useRef, useState } from "react";
export default function HeaderMenu() {
  const textAreaRef = useRef(null);
  const [copyEmailVisible, setCopyEmailVisible] = useState(false);
  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
  }
  const isDesktop = window.innerWidth > 650;
  return (
    <div
      className={`${window.innerWidth > 650 ? menu.desktop : ""} ${
        menu.headerMenu
      }`}
    >
      <div className={menu.item}>
        <a href={resumepdf} target="_blank" className={menu.hideStyle}>
          <i className={`icofont-file-document ${menu.icon}`}></i>Download
          Resume{" "}
        </a>{" "}
      </div>
      <div className={menu.item}>
        {" "}
        <a
          className={`${menu.hideStyle}`}
          target="_blank"
          href="https://www.linkedin.com/in/mohaiminalaoun"
        >
          <i className={`icofont-linkedin ${menu.icon}`}></i>LinkedIn
        </a>
      </div>
      <div className={menu.item}>
        {" "}
        <a
          className={`${menu.hideStyle}`}
          target="_blank"
          href="https://www.github.com/mohaiminalaoun"
        >
          <i className={`icofont-github ${menu.icon}`}></i>Github
        </a>
      </div>
      <div className={`${menu.item} ${common.flex}`}>
        <span>
          <a
            className={`${menu.hideStyle}`}
            target="_blank"
            href="mailto:mohaimin.alaoun@gmail.com"
          >
            <i className={`icofont-email ${menu.icon}`}></i>Send Email
          </a>
        </span>
        {!isDesktop ? (
          <div className={menu.inputGroup}>
            <input
              className={menu.input}
              ref={textAreaRef}
              readOnly
              value={"mohaimin.al.aoun@gmail.com"}
            ></input>
            <button onClick={copyToClipboard} className={menu.copyBtnCtr}>
              <i className={`icofont-copy ${menu.icon} ${menu.copyBtn}`}></i>
            </button>
          </div>
        ) : null}
        {isDesktop ? (
          <i
            onClick={() => {
              setCopyEmailVisible(!copyEmailVisible);
            }}
            className={`icofont-rounded-down ${menu.icon} ${menu.showCopyBtn}`}
          ></i>
        ) : null}
      </div>
      {isDesktop && copyEmailVisible ? (
        <div className={`${menu.item} ${menu.copyEmailDesktop}`}>
          <div className={menu.inputGroup}>
            <input
              className={menu.input}
              ref={textAreaRef}
              readOnly
              value={"mohaimin.al.aoun@gmail.com"}
            ></input>
            <button onClick={copyToClipboard} className={menu.copyBtnCtr}>
              <i className={`icofont-copy ${menu.icon} ${menu.copyBtn}`}></i>
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
