import React from "react";
import { useState } from "react";
import styles from "./Teams.module.css";
import common from "./CommonStyles.module.css";
export default function Teams() {
  const [inputVal, setInputVal] = useState("");
  const startsWith = (letter) => {
    const lowerCase = letter.toLowerCase();
    return (
      inputVal.length === 0 ||
      inputVal[0] === letter ||
      inputVal[0] === lowerCase
    );
  };
  return (
    <div className={styles.teamsContainer}>
      <div className={styles.inputCtr}>
        <i
          className={`icofont-search-1 ${styles.searchIcon} ${common.mr4}`}
        ></i>
        <input
          className={styles.findTeam}
          placeholder="Find a team..."
          value={inputVal}
          onChange={(e) => setInputVal(e.target.value)}
        ></input>
      </div>

      {startsWith("C") ? (
        <div className={styles.team}>
          <i className={`icofont-users ${common.mr4} ${styles.icon}`}></i>
          <a className={styles.teamName} href={"https://www.clarabridge.com/"}>
            Clarabridge/Front-end Software Engineer
          </a>
        </div>
      ) : null}
      {startsWith("M") ? (
        <div className={styles.team}>
          <i className={`icofont-users ${common.mr4} ${styles.icon}`}></i>
          <a className={styles.teamName} href={"https://www.microstrategy.com"}>
            MicroStrategy/Front-end Software Engineer
          </a>
        </div>
      ) : null}
      {startsWith("D") ? (
        <div className={styles.team}>
          <i className={`icofont-users ${common.mr4} ${styles.icon}`}></i>
          <a
            className={styles.teamName}
            href="https://www.digitalreasoning.com"
          >
            Digital Reasoning/Software Engineering Intern
          </a>
        </div>
      ) : null}
    </div>
  );
}
